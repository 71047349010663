import React from "react"
import { ReactComponent as ChevronLeft } from "@images/icons/chevron-left.svg"
import { ReactComponent as ChevronRight } from "@images/icons/chevron-right.svg"

export const DotButton = ({ selected, onClick }) => (
  <button
    aria-hidden="true"
    className={`embla__dot ${selected ? "is-selected" : ""}`}
    type="button"
    onClick={onClick}
  />
)

export const PrevButton = ({ enabled, onClick }) => (
  <button
    className="embla__button embla__button--prev"
    onClick={onClick}
    disabled={!enabled}
    aria-hidden="true"
  >
    <ChevronLeft height="20px" width="20px" />
  </button>
)

export const NextButton = ({ enabled, onClick }) => (
  <button
    className="embla__button embla__button--next"
    onClick={onClick}
    disabled={!enabled}
    aria-hidden="true"
  >
    <ChevronRight height="20px" width="20px" />
  </button>
)
