import React, { useState, useEffect, useCallback } from "react"
import PropTypes from "prop-types"
import { useEmblaCarousel } from "embla-carousel/react"

import BrandButton from "@components/common/BrandButton"
import { DotButton, PrevButton, NextButton } from "./EmblaCarouselButtons"
import classnames from "classnames"

import "./style.scss"
import "./embla.scss"

const PageCarousel = props => {
  const [viewportRef, embla] = useEmblaCarousel({
    align: props.align,
    draggable: !props.disableControls,
    skipSnaps: true,
  })

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [scrollSnaps, setScrollSnaps] = useState([])

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla])
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla])
  const scrollTo = useCallback(index => embla && embla.scrollTo(index), [embla])
  const [scrollProgress, setScrollProgress] = useState(0)

  const { onSelect, onScroll } = props
  const onSelectCallback = useCallback(() => {
    if (!embla) return
    setSelectedIndex(embla.selectedScrollSnap())
    setPrevBtnEnabled(embla.canScrollPrev())
    setNextBtnEnabled(embla.canScrollNext())
    onSelect(embla)
  }, [embla, setSelectedIndex, onSelect])

  const onScrollCallback = useCallback(() => {
    if (!embla) return
    const progress = Math.max(0, Math.min(1, embla.scrollProgress()))
    setScrollProgress(progress * 100)
    onScroll(embla)
  }, [embla, setScrollProgress, onScroll])

  useEffect(() => {
    if (!embla) return
    onSelectCallback()
    onScrollCallback()
    setScrollSnaps(embla.scrollSnapList())
    embla.on("select", onSelectCallback)
    embla.on("scroll", onScrollCallback)
  }, [embla, setScrollSnaps, onSelectCallback, onScrollCallback])

  const contentSlides = [props.contentSlide, ...(props.slides || [])].filter(
    s => s
  )

  if (embla) {
    embla.on("init", eventName => {
      props.onInit(embla)
    })
  }

  return (
    <div className="page-carousel">
      <div className="page-carousel__wrapper">
        <div className="page-carousel__copy--mobile">
          {!!props.contentSlide && props.contentSlide}
        </div>
        {!!contentSlides.length && (
          <div className="page-carousel__carousel">
            <div className="embla">
              <div className="embla__viewport" ref={viewportRef}>
                <ul className="embla__container">
                  {contentSlides.map((slide, index) => {
                    return (
                      <li
                        key={`pageCarousel__slide-${index}--${props.childKeyIdentifier}`}
                        className={classnames(
                          "page-carousel__slide",
                          "li-reset",
                          {
                            "page-carousel__slide--first":
                              !!props.contentSlide && index === 0,
                          }
                        )}
                      >
                        {slide}
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
      {!props.disableControls && !!(props.slides || {}).length && (
        <div className="embla__controls">
          <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
          {props.navStyle === "dots" && (
            <div className="embla__dots">
              {scrollSnaps.map((_, index) => (
                <DotButton
                  key={index}
                  selected={index === selectedIndex}
                  onClick={() => scrollTo(index)}
                />
              ))}
            </div>
          )}
          {props.navStyle === "progress" && (
            <div className="embla__progress">
              <div
                className="embla__progress__bar"
                style={{ transform: `translateX(${scrollProgress}%)` }}
              />
            </div>
          )}

          <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
        </div>
      )}

      {props.cta && (
        <BrandButton
          className="page-carousel__cta--mobile"
          href={props.cta.url}
          text={props.cta.title}
        />
      )}
    </div>
  )
}

PageCarousel.propTypes = {
  align: PropTypes.oneOf(["start", "center"]),
  childKeyIdentifier: PropTypes.string,
  maxItems: PropTypes.number,
  contentSlide: PropTypes.object,
  slides: PropTypes.array,
  navStyle: PropTypes.oneOf(["dots", "progress"]),
  cta: PropTypes.shape({
    url: PropTypes.string,
    title: PropTypes.string,
  }),
  disableControls: PropTypes.bool,
  onInit: PropTypes.func,
  onSelect: PropTypes.func,
  onScroll: PropTypes.func,
}

PageCarousel.defaultProps = {
  align: "start",
  childKeyIdentifier: "child", // change this if multiple carousels exist on the same page
  slides: [],
  maxItems: 5,
  navStyle: "progress",
  disableControls: false,
  onInit: embla => {},
  onSelect: embla => {},
  onScroll: embla => {},
}

export default PageCarousel
